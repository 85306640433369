import Vue from 'vue'
import App from './App.vue'
//导入 router
import router from '@/router'
//导入 store
import store from './store';
//导入 element-ui 组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
//导入 axios 组件
import axios from 'axios';
Vue.prototype.$axios = axios
//配置全局接口
// Vue.prototype.$http = 'http://localhost:8080/api'
Vue.prototype.$http = 'http://8.137.80.96:8080/api'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
