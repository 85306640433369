<template>
  <div class="header-container">
    <div class="user-info">
      <div class="username">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link"
            >{{ username }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user">个人中心</el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-lock"
              @click.native="toChangePwdPage"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item
              icon="el-icon-s-unfold"
              divided
              @click.native="logOut"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-avatar :size="size" :src="circleUrl"></el-avatar>
    </div>
  </div>
</template>

<script>
//从 vuex 中按需导入 mapState 函数
import { mapState } from "vuex";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      size: "large",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },

  // 将当前组件需要的全局数据，映射为 computed 计算属性
  computed: {
    ...mapState(["username"]),
  },

  methods: {
    ...mapMutations(["setUsername"]),

    // 修改密码
    toChangePwdPage() {
      this.$router.push("/home/changePwd");
    },

    // 登出
    logOut() {
      //删除 token 跳转到登录页面
      localStorage.removeItem("token");
      this.$router.push("/loginMain");
    },
  },
};
</script>

<style>
.header-container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.user-info {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.el-avatar {
  margin-right: 10px;
}
/* .my-log-out-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: url("~@/assets/images/log-out-icon.png") center no-repeat;
  background-size: 22px;
  display: flex;
} */
</style>