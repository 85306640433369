//1.导入 Vue 和 VueRouter 包
import Vue from "vue";
import VueRouter from "vue-router";

//2.调用 Vue.use() 函数，把 VueRouter 安转为 Vue 的插件
Vue.use(VueRouter)

import Home from '@/views/Home.vue';
import LoginMain from "@/views/LoginMain.vue";
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Index from '@/views/Index.vue';
import MenuPage from '@/views/MenuPage.vue';
import UserPage from '@/views/UserPage.vue';
import RolePage from '@/views/RolePage.vue';
import ProductPage from '@/views/ProductPage.vue';
import MerchantPage from '@/views/MerchantPage.vue';
import CategoryPage from '@/views/CategoryPage.vue';
import DetailsPage from '@/views/DetailsPage.vue';
import MerchantSortPage from '@/views/MerchantSortPage.vue';
import TestPage from '@/views/TestPage.vue';
import ChangePwd from "@/views/ChangePwd.vue";
//3.创建路由的实例对象	
const router = new VueRouter({
    mode: 'history', // 使用history模式
    routes: [
        { path: '/', redirect: '/loginMain' },
        {
            path: '/home', component: Home,
            children: [
                { path: '', component: Index },
                { path: 'menuPage', component: MenuPage },
                { path: 'userPage', component: UserPage },
                { path: 'rolePage', component: RolePage },
                { path: 'productPage', component: ProductPage },
                { path: 'merchantPage', component: MerchantPage },
                { path: 'categoryPage', component: CategoryPage },
                { path: 'detailsPage', component: DetailsPage },
                { path: 'merchantSortPage', component: MerchantSortPage },
                { path: 'testPage', component: TestPage },
                { path: 'changePwd', component: ChangePwd },

            ]
        },
        {
            path: '/loginMain',
            component: LoginMain,
            children: [
                { path: '', component: Login },
                { path: 'register', component: Register },
            ]
        },
    ]
})

//声明全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.path !== '/loginMain' && to.path != '/loginMain/register') {
        const token = localStorage.getItem('token');
        if (token) {
            next()
        } else {
            next('/loginMain')
        }
    } else {
        next()
    }
})

//4.向外共享路由的实例对象
export default router